import { FC } from 'react'
import { Checkbox } from 'components/form/checkbox/Checkbox'
import { Item as TItem } from '../types'
import { twMerge } from 'tailwind-merge'
import { ItemPath } from './ItemPath'

export interface Props {
  className?: string
  item: TItem
  onItemClick: (id: number, checked: boolean) => void
  path?: string[]
}

export const SkeletonItem = () => (
  <div className="h-11 grow my-2 mx-9 bg-neutral-100 animate-pulse"></div>
)

export const Item: FC<Props> = ({ className, item, onItemClick, path }) => {
  return (
    <div className={twMerge('flex flex-col', className)}>
      {path && <ItemPath path={path} />}

      <label
        key={item.id}
        className={twMerge(
          'flex flex-row items-center p-3 my-1 text-sm font-semibold rounded cursor-pointer select-none bg-neutral-200',
          item.disabled && 'cursor-not-allowed opacity-50',
        )}
      >
        <Checkbox
          id={`item-${item.id}`}
          checked={item.checked}
          disabled={item.disabled}
          onChange={() => onItemClick(item.id, !item.checked)}
          className="mr-2"
        />
        {item.label}
        <span className="text-[11px] font-normal text-neutral-600 ml-auto">
          {item.secondaryLabel}
        </span>
      </label>
    </div>
  )
}
