import { FC } from 'react'
import { Group as GroupType } from '../types'
import { Item, Props as ItemProps } from './Item'
import project from 'assets/project.svg'
import { Label } from './Label'

export type GroupProps = Omit<GroupType, 'id'> & {
  collapsed: boolean
  onCollapse: () => void
  onExpand: () => void
  onItemClick: ItemProps['onItemClick']
}

export const Group: FC<GroupProps> = ({
  collapsed,
  name,
  onCollapse,
  onExpand,
  onItemClick,
  subGroups,
}) => (
  <section className="flex-col mt-4 mb-6" key={name}>
    <button onClick={collapsed ? onExpand : onCollapse}>
      <h2 className="flex flex-row items-center mb-2 text-xs font-medium uppercase text-neutral-500">
        <img src={project} className="w-8 h-8 mr-2" />
        <Label
          content={name}
          hideCount={!collapsed}
          count={subGroups.flatMap((s) => s.items).length}
        />
      </h2>
    </button>
    {(collapsed ? [] : subGroups).map(({ name, items }) => (
      <div key={name} className="mb-4">
        <h3 className="text-xs font-medium text-neutral-500">{name}</h3>
        {items.map((item) => (
          <Item key={item.id} item={item} onItemClick={onItemClick} />
        ))}
      </div>
    ))}
  </section>
)
