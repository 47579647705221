import classNames from 'classnames'
import { IconButton, MenuButton } from 'components/buttons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  breakLabel: string
  onBreakAdd: () => void
  onBreakRemove: () => void
  removeDisabled: boolean
  addDisabled: boolean
}

export const BreakControl: FC<Props> = ({
  addDisabled,
  breakLabel,
  onBreakAdd,
  onBreakRemove,
  removeDisabled,
}) => {
  const { t } = useTranslation()

  const menuItems = [
    {
      content: t('features.timeLogging.removeBreak', {
        breakNumber: breakLabel,
      }),
      onClick: onBreakRemove,
      disabled: removeDisabled,
    },
    {
      content: t('features.timeLogging.addNewBreak'),
      onClick: onBreakAdd,
      disabled: addDisabled,
    },
  ]

  return (
    <MenuButton
      button={({ toggleMenuHidden }) => (
        <IconButton onClick={toggleMenuHidden} />
      )}
      className={({ hidden }) =>
        classNames('relative ml-auto group-hover:block', { hidden })
      }
      menuItems={menuItems}
    />
  )
}
