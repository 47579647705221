import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
  content: string
  count: number
  hideCount?: boolean
}

export const Label: FC<Props> = ({
  className,
  content,
  count,
  hideCount = false,
}) => (
  <span
    className={twMerge(
      'text-xs font-medium uppercase text-neutral-500',
      className,
    )}
  >
    {content} {!hideCount && `(${count})`}
  </span>
)
