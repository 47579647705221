import { FC } from 'react'
import classNames from 'classnames'

interface Props {
  children: React.ReactNode
  className?: string
}

export const Header: FC<Props> = ({ className, children }) => {
  return (
    <div className={classNames(className, 'px-8 pt-4 bg-neutral-800')}>
      {children}
    </div>
  )
}
